import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { createAction, props, Store } from '@ngrx/store';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { ITrackingService, TrackingEvent } from '@shiftbase-com/models';
import { combineLatest, distinctUntilChanged, filter, map, skip } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AppState } from '../reducers';
import { getAccount } from '../reducers/account/account.service';
import { getAuthenticatedUserId } from '../reducers/auth/auth.service';

interface FormGroupControls {
  [key: string]: AbstractControl;
}

interface ValidationError {
  controlName: string;
  errorName: string;
}

export const trackEvent = createAction('[Tracking] Track Event', props<{ event: TrackingEvent }>());

@Injectable({
  providedIn: 'root',
})
export class TrackingService implements ITrackingService {
  private readonly options = {
    version: environment.version,
    name: 'Web App',
  };
  private _isInitialized = false;

  private readonly rudderanalytics = new RudderAnalytics();

  public constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {}

  public get isInitialized() {
    return this._isInitialized;
  }

  public init: ITrackingService['init'] = async () =>
    new Promise<void>((resolve) => {
      this.rudderanalytics.load(environment.rudderWriteKey, environment.rudderDataPlaneUrl, {
        onLoaded: () => {
          resolve();
        },
        plugins: ['XhrQueue'],
      });
    });

  public event: ITrackingService['event'] = (event, properties) => {
    this.rudderanalytics.track(event, properties, this.options);
    this.store.dispatch(trackEvent({ event }));
  };

  public identify: ITrackingService['identify'] = (userId, traits) => {
    this.rudderanalytics.identify(userId, traits as Record<string, any>, this.options);
  };

  public unidentify() {
    this.rudderanalytics.reset();
    this.rudderanalytics.identify('', { isLoggedIn: false }, this.options);
  }

  public page() {
    this.rudderanalytics.page(undefined, undefined, undefined, this.options);
  }

  public get anonymousId(): string {
    return this.rudderanalytics.getAnonymousId();
  }

  public startTracking() {
    if (!environment.rudderstackEnabled || this.isInitialized) {
      return;
    }
    this.init().then(() => {
      this._isInitialized = true;
      this.trackIdentity();
      this.trackPageViews();
    });
  }

  private trackIdentity(): void {
    // skip first value because it's the initial value
    void combineLatest([
      this.store.select(getAuthenticatedUserId).pipe(skip(1)),
      this.store.select(getAccount).pipe(
        skip(1),
        map((account) => (account ? { account_id: account.id, company_name: account.company } : undefined)),
      ),
    ])
      .pipe(
        map(([userId, traits]) => (userId && traits ? { userId, traits } : undefined)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe((payload) => {
        if (payload) {
          this.identify(payload.userId, payload.traits);
        } else {
          this.unidentify();
        }
      });
  }

  private trackPageViews(): void {
    void this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(() => {
        this.page();
      });
  }

  public formValidationToError(controls: FormGroupControls): string {
    return this.getFormValidationErrors(controls)
      .map((error) => `${error.controlName}:${error.errorName}`)
      .join(',');
  }

  private getFormValidationErrors(controls: FormGroupControls): ValidationError[] {
    let errors: ValidationError[] = [];
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      if (control instanceof FormGroup || control instanceof UntypedFormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control.controls));
      }
      const controlErrors: ValidationErrors | null = controls[key].errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach((keyError) => {
          errors.push({
            controlName: key,
            errorName: keyError,
          });
        });
      }
    });
    return errors;
  }

  /**
   * Some events require an originator to be passed as a property.
   * For now this is mostly the case with modal interaction events.
   * Because the way our old modals are set up, we lose any context of parent routes.
   * Therefore we need to parse it manually from the url.
   */
  public getOldModalOriginator() {
    return this.router.parseUrl(this.router.url)?.root?.children?.['primary']?.segments?.[0]?.path;
  }

  public getUrlSegments(outlet = 'primary') {
    return this.router.parseUrl(this.router.url)?.root?.children?.[outlet]?.segments?.map((segment) => segment.path);
  }
}
