export const isColorDark = (hexcolor: string): boolean => {
  // Hexcolor needs to be stripped bedore parsed to an int, otherwise, const [r, g, b] will be NaN.
  const strippedHexcolor = hexcolor.replace('#', '');

  const r = parseInt(strippedHexcolor.substr(0, 2), 16);
  const g = parseInt(strippedHexcolor.substr(2, 2), 16);
  const b = parseInt(strippedHexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq < 128;
};

export const hexToRGB = (color: string) => {
  // Hexcolor needs to be stripped bedore parsed to an int, otherwise, const [r, g, b] will be NaN.
  const strippedHexcolor = color.replace('#', '');

  const r = parseInt(strippedHexcolor.substr(0, 2), 16);
  const g = parseInt(strippedHexcolor.substr(2, 2), 16);
  const b = parseInt(strippedHexcolor.substr(4, 2), 16);

  return `${r}, ${g}, ${b}`;
};
