/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { isAfter, startOfDay } from 'date-fns';
import { remove as removeDiacritics } from 'diacritics';
import orderBy from 'lodash-es/orderBy';
import pickBy from 'lodash-es/pickBy';
import reject from 'lodash-es/reject';
import some from 'lodash-es/some';
import sortBy from 'lodash-es/sortBy';
import { FileItem } from 'ng2-file-upload';
import { createSelector } from 'reselect';
import { Observable, throwError as observableThrowError, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import u from 'updeep';

import { format, parseDate } from '../../../shared/date.helper';
import { getEmployeeSortDirection, getEmployeeSortField } from '../../account/account.service';
import { getAuthenticatedUserId } from '../../auth/auth.service';
import { getEmployeeTeamDepartments, getEmployeeTeamDepartmentsWithoutFlexpool } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { AttachmentApi } from '../attachment.api';
import { TeamModel, TeamType } from '../team/team.model';
import { getTeamEntities } from '../team/team.service';
import { EmployeeAction } from './employee.action';
import { EmployeeApi } from './employee.api';
import {
  EmployeeModel,
  EmployeeModelWithDepartments,
  EmployeeState,
  EmployeeTeamSavePayload,
  EmployeeWithContractInfo,
  HasStartAndEndDate,
  TimeDistributionResponse,
  UserAbsencePolicies,
} from './employee.model';

export function employeeIsActive(employee: EmployeeModel): boolean {
  return employee.enddate === null || employee.enddate > format(new Date(), 'yyyy-MM-dd');
}

@Injectable()
export class EmployeeService {
  public clearEmployeeIds$ = new Subject();
  public state: Observable<EmployeeState>;
  public employees: Observable<EmployeeModel[]>;

  public constructor(
    private store: Store<AppState>,
    private api: EmployeeApi,
    private attachmentApi: AttachmentApi,
  ) {
    this.state = store.select(getEmployeeState);
    this.employees = store.select(getEmployees);
  }

  public load() {
    this.store.dispatch(EmployeeAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(EmployeeAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public loadAbsencePolicies(userIds: string[], date: string): Observable<UserAbsencePolicies[]> {
    return this.api.absencePolicies(userIds, date);
  }

  public add(data): Observable<any> {
    this.store.dispatch(EmployeeAction.add(data));

    return this.api.add(data).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.addSuccess(response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, data) {
    this.store.dispatch(EmployeeAction.update(data));

    return this.api.update(id, data).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.updateSuccess(response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public activate(id) {
    this.store.dispatch(EmployeeAction.activate(id));

    return this.api.activate(id).pipe(
      map((response) => {
        this.store.dispatch(EmployeeAction.activateSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.activateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    this.store.dispatch(EmployeeAction.fetch(id));

    return this.api.fetch(id).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.fetchSuccess(response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(id) {
    this.store.dispatch(EmployeeAction.remove(id));

    return this.api.remove(id).pipe(
      tap(() => this.store.dispatch(EmployeeAction.removeSuccess(id))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public forceRemove(id, data) {
    this.store.dispatch(EmployeeAction.anonymize(id));

    return this.api.remove(id, data).pipe(
      tap((response) => {
        this.store.dispatch(EmployeeAction.anonymizeSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.anonymizeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public anonymize(id) {
    this.store.dispatch(EmployeeAction.anonymize(id));

    return this.api.anonymize(id).pipe(
      tap((response) => {
        this.store.dispatch(EmployeeAction.anonymizeSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.anonymizeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(data) {
    if (data.id) {
      return this.update(data.id, data);
    }

    data = u.omit('id', data);

    return this.add(data);
  }

  public saveMany(data) {
    return this.api.saveMany(data, EmployeeAction.saveMany(data)).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.saveManySuccess(response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.saveManyFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public loadFiles(userId: string) {
    return this.api.loadFiles(userId, EmployeeAction.loadFiles(userId)).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.loadFilesSuccess(userId, response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.loadFilesFailed(userId, response));
        return observableThrowError(response);
      }),
    );
  }

  public download(attachmentId: string, fileName: string) {
    return this.attachmentApi.download(attachmentId, fileName);
  }

  public saveFile(employeeId, data) {
    if (data.id) {
      return this.updateFile(employeeId, data);
    }

    return this.addFile(employeeId, data);
  }

  public addFile(employeeId, data): Observable<any> {
    return this.api.addFile(employeeId, data, EmployeeAction.addFile(employeeId, data)).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.addFileSuccess(employeeId, response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.addFileFailed(employeeId, response));
        return observableThrowError(response);
      }),
    );
  }

  public updateFile(employeeId, data): Observable<any> {
    return this.api.updateFile(data.id, data, EmployeeAction.editFile(employeeId, data)).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.editFileSuccess(employeeId, response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.editFileFailed(employeeId, response));
        return observableThrowError(response);
      }),
    );
  }

  public deleteFile(employeeId: string, attachmentId: string) {
    return this.attachmentApi.delete(attachmentId, EmployeeAction.deleteFile(employeeId, attachmentId)).pipe(
      tap(() => this.store.dispatch(EmployeeAction.deleteFileSuccess(employeeId, attachmentId))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.deleteFileFailed(employeeId, attachmentId));
        return observableThrowError(response);
      }),
    );
  }

  public loadNotes(userId: string) {
    return this.api.loadNotes(userId, EmployeeAction.loadNotes(userId)).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.loadNotesSuccess(userId, response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.loadNotesFailed(userId, response));
        return observableThrowError(response);
      }),
    );
  }

  public saveNote(employeeId, data) {
    if (data.id) {
      return this.updateNote(employeeId, data);
    }

    return this.addNote(employeeId, data);
  }

  public addNote(employeeId, data): Observable<any> {
    return this.api.addNote(employeeId, data, EmployeeAction.addNote(employeeId, data)).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.addNoteSuccess(employeeId, response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.addNoteFailed(employeeId, response));
        return observableThrowError(response);
      }),
    );
  }

  public updateNote(employeeId, data): Observable<any> {
    return this.api.updateNote(data.id, data, EmployeeAction.editNote(employeeId, data)).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.editNoteSuccess(employeeId, response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.editNoteFailed(employeeId, response));
        return observableThrowError(response);
      }),
    );
  }

  public deleteNote(employeeId: string, userNoteId: string) {
    return this.api.deleteNote(userNoteId, EmployeeAction.deleteNote(employeeId, userNoteId)).pipe(
      tap(() => this.store.dispatch(EmployeeAction.deleteNoteSuccess(employeeId, userNoteId))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.deleteNoteFailed(employeeId, userNoteId));
        return observableThrowError(response);
      }),
    );
  }

  public deleteAvatar(employeeId: string) {
    return this.api.deleteAvatar(employeeId).pipe(
      tap(() => {
        this.store.dispatch(EmployeeAction.deleteAvatarSuccess(employeeId));
      }),
    );
  }

  public saveTeams(employeeId: string, data: EmployeeTeamSavePayload) {
    return this.api.saveTeams(employeeId, data, EmployeeAction.updateTeams(employeeId, data)).pipe(
      tap((response) => this.store.dispatch(EmployeeAction.updateTeamsSuccess(employeeId, response))),
      catchError((response) => {
        this.store.dispatch(EmployeeAction.updateTeamsFailed(employeeId, response));
        return throwError(() => response);
      }),
    );
  }

  public savePermissions(employeePermission) {
    const employeeId = employeePermission.user_id;
    const permissions = employeePermission.Department;

    return this.api
      .savePermissions(employeeId, permissions, EmployeeAction.updatePermissions(employeeId, permissions))
      .pipe(
        tap((response) => this.store.dispatch(EmployeeAction.updatePermissionsSuccess(employeeId, response))),
        catchError((response) => {
          this.store.dispatch(EmployeeAction.updatePermissionsFailed(employeeId, response));
          return observableThrowError(response);
        }),
      );
  }

  public setSelectedEmployeeIds(selectedEmployeeIds) {
    this.store.dispatch(EmployeeAction.bulkAdd(selectedEmployeeIds));
  }

  public getTimeDistribution(employeeId: string, year: string): Observable<TimeDistributionResponse> {
    return this.api.getTimeDistribution(employeeId, year);
  }

  public getBalances(employeeId: string, year: string) {
    return this.api.getBalances(employeeId, year);
  }

  /**
   * Clear selected employees
   */
  public clearSelectedEmployeeIds() {
    this.clearEmployeeIds$.next(null);
    this.store.dispatch(EmployeeAction.bulkEmpty());
  }

  /**
   * Call send message api.
   *
   * @param messageData
   * @returns {Observable<any>}
   */
  public sendMessage(messageData) {
    return this.api.sendMessage(messageData);
  }

  /**
   * Call send password api.
   *
   * @param employeeIds
   * @returns {Observable<any>}
   */
  public sendPassword(employeeIds) {
    return this.api.sendPassword(employeeIds);
  }

  public resendInvite(employeeIds) {
    return this.api.resendInvite(employeeIds);
  }

  public getUploaderEmployeeAvatar(getEmployeeId, uploadSuccessFn, failedFn) {
    const employeeId = getEmployeeId();

    const uploader = this.api.getEmployeeAvatarFileUploader(employeeId);

    // make sure we are allowed to upload
    uploader.onBeforeUploadItem = (item: FileItem) => {
      this.store.dispatch(EmployeeAction.updateAvatar(employeeId, item.file));
      item.withCredentials = false;
    };

    if (uploadSuccessFn) {
      uploader.onSuccessItem = (item: FileItem, response, status, headers) => {
        const res = JSON.parse(response);
        uploadSuccessFn(item, res.data, status, headers);
        this.store.dispatch(EmployeeAction.updateAvatarSuccess(employeeId, res.data));
      };
    }

    if (failedFn) {
      uploader.onWhenAddingFileFailed = (item, filter, options) => {
        failedFn(item, filter, options);
        this.store.dispatch(EmployeeAction.updateAvatarFailed(employeeId, 'err'));
      };
    }

    return uploader;
  }
}

export const sortEmployees =
  (sortField: string, sortDirection: string) =>
  (employees: EmployeeModel[]): EmployeeModel[] => {
    const direction = sortDirection === 'ASC' ? 'asc' : 'desc';

    if (sortField === 'last_name') {
      return orderBy(employees, [sortField], [direction]);
    }

    if (sortField === 'order') {
      return orderBy(employees, [(employee: EmployeeModel) => Number(employee.order), 'last_name'], [direction, 'asc']);
    }

    return orderBy(employees, [sortField, 'last_name'], [direction, 'asc']);
  };

export const mapAndSortEmployees = createSelector(
  [(state) => getEmployeeSortField(state), (state) => getEmployeeSortDirection(state)],
  (sortField, sortDirection) => mapAndSortEntities(sortEmployees(sortField, sortDirection)),
);

export const getEmployeeState = (state: AppState) => state.orm.employees;

export const getEmployeeIds = compose((state) => state.items, getEmployeeState);
export const getEmployeeEntities = compose((state) => state.itemsById, getEmployeeState);
export const getSelectedEmployeesId = compose((state) => state.selectedEmployeeIds, getEmployeeState);

export const getEmployees = createSelector(
  getEmployeeIds,
  getEmployeeEntities,
  mapAndSortEmployees,
  (ids, entities, sortFunc) =>
    //Filter out anonymized employees everywhere
    sortFunc(ids, entities).filter((employee: EmployeeModel) => !employee.anonymized),
);

export const getSelectedEmployees = createSelector(
  getEmployees,
  getSelectedEmployeesId,
  (employees: EmployeeModel[], selectedEmployeesId) =>
    employees.filter((employee) => selectedEmployeesId.indexOf(employee.id) > -1),
);

export const getActiveEmployees = createSelector(getEmployees, (employees: EmployeeModel[]) =>
  employees.filter((employee) => employeeIsActive(employee)),
);

export const getActiveEmployeesMustContainForPermissions = (employeeIds: string[]) =>
  createSelector(getEmployees, (employees: EmployeeModel[]): EmployeeModel[] =>
    employees.filter(
      (employee) => (employeeIsActive(employee) && !!employee.email) || employeeIds.includes(employee.id),
    ),
  );

export const getInactiveEmployees = createSelector(getEmployees, (employees: EmployeeModel[]) =>
  reject(employees, (employee) => employeeIsActive(employee)),
);

export const getEmployee = (id: string) =>
  createSelector(
    getEmployeeEntities,
    getEmployeeTeamDepartmentsWithoutFlexpool,
    getEmployeeTeamDepartments,
    (entities, employeeDepartmentsWithoutFlexpool, employeeDepartments): EmployeeModelWithDepartments => {
      const entity: EmployeeModel = mapEntity(id, entities);
      if (!entity || entity.anonymized) {
        return undefined;
      }
      return {
        ...entity,
        departments: employeeDepartmentsWithoutFlexpool[entity.id],
        departmentsWithFlexpool: employeeDepartments[entity.id],
      };
    },
  );

export const getEmployeeAttachment = (employeeId, attachmentId) =>
  compose((employee: EmployeeModel) => {
    if (!employee.Files) {
      return void 0;
    }

    return employee.Files[attachmentId];
  }, getEmployee(employeeId));

export const getEmployeeAttachments = (employeeId) =>
  compose((employee: EmployeeModel) => {
    if (!employee.Files) {
      return [];
    }

    return sortBy(employee.Files, 'created');
  }, getEmployee(employeeId));

export const getEmployeeNote = (employeeId, noteId) =>
  compose((employee: EmployeeModel) => {
    if (!employee.Notes) {
      return void 0;
    }

    return employee.Notes[noteId];
  }, getEmployee(employeeId));

export const employeeInDepartment = (
  departmentIds: string[],
  teamEntities: Record<string, TeamModel>,
  mustContainUserId?: string | string[],
  allowHiddenTeam = true,
) => {
  if (!allowHiddenTeam) {
    teamEntities = pickBy(teamEntities, (team) => team.type !== TeamType.HIDDEN);
  } else {
    teamEntities = pickBy(teamEntities, (team) => team.type !== TeamType.FLEXPOOL);
  }

  const containUserIds = !Array.isArray(mustContainUserId) ? [mustContainUserId] : mustContainUserId;

  return (employee) => {
    if (mustContainUserId && containUserIds?.length && containUserIds?.includes(employee.id)) {
      return true;
    }

    if (!employee.Team || !teamEntities) {
      return false;
    }

    const employeeDepartments: string[] = employee.Team.map((teamId) => {
      if (!teamEntities[teamId]) {
        return null;
      }

      return teamEntities[teamId].department_id;
    }).filter((departmentId) => !!departmentId);

    return some(employeeDepartments, (employeeDepartment) => departmentIds.indexOf(employeeDepartment) !== -1);
  };
};

export const getEmployeesWithSelectedTeamDepartment = (
  selectedDepartments: string[] = [],
  mustContainUserId = void 0,
  allowHiddenTeam = true,
) =>
  createSelector(getEmployees, getTeamEntities, (employees, teamEntities) => {
    const filterFn = employeeInDepartment(selectedDepartments, teamEntities, mustContainUserId, allowHiddenTeam);

    return employees.filter((employee) => filterFn(employee));
  });

export const employeeActiveInPeriod =
  (minDate: string, maxDate: string) => (employee: EmployeeModel | EmployeeWithContractInfo | HasStartAndEndDate) => {
    if (employee) {
      return maxDate >= employee.startdate && (minDate < employee.enddate || employee.enddate === null);
    } else {
      return false;
    }
  };

export const canEmployeeRequestAbsence = (absenceStartDate: string) => (employee?: EmployeeModel) => {
  if (!employee) {
    return false;
  }
  const activeInPeriod = employeeActiveInPeriod(absenceStartDate, absenceStartDate)(employee);

  if (activeInPeriod) {
    return true;
  }

  const today = format(new Date(), 'yyyy-MM-dd');

  // // If absence is in future and absence is after employee started working
  // // (even if employee is no longer active)
  if (absenceStartDate >= employee.startdate && employee.startdate >= today) {
    return true;
  }

  /**
   * Check if employee is active today and if the request date is after the current date
   * This prevents currently active employees from requesting an absence in the past (eg. before contract start date)
   * They will still be able to request for absences in the future
   */
  if (isAfter(startOfDay(parseDate(employee.startdate)), startOfDay(parseDate(absenceStartDate)))) {
    return false;
  }

  const activeToday = employeeActiveInPeriod(today, today)(employee);

  if (!activeToday || absenceStartDate <= today) {
    return false;
  }

  return true;
};

export const employeeNameFilter = (search) => {
  search = removeDiacritics(search).trim().toLowerCase();

  return (employee: EmployeeModel) => {
    if (search === '') {
      return true;
    }

    const check = removeDiacritics(employee.name).toLowerCase();

    return check.indexOf(search) !== -1;
  };
};

export const employeeListSearchFilter = (search) => {
  if (!search) {
    search = '';
  }
  search = removeDiacritics(search).trim().toLowerCase();

  return (employee: EmployeeModel) => {
    if (search === '') {
      return true;
    }

    const nameCheck = employee && employee.name && removeDiacritics(employee.name).toLowerCase().includes(search);
    const emailCheck = !!(employee && employee.email && employee.email.toLowerCase().includes(search));
    const phoneCheck = !!(employee && employee.phone_nr && employee.phone_nr.includes(search));
    const mobileCheck = !!(employee && employee.mobile_nr && employee.mobile_nr.includes(search));
    const cityCheck = !!(employee && employee.city && employee.city.toLowerCase().includes(search));
    const employeeNumberCheck = !!(employee && employee.employee_nr && employee.employee_nr.includes(search));

    return nameCheck || emailCheck || phoneCheck || mobileCheck || cityCheck || employeeNumberCheck;
  };
};

interface HasEmployeeId {
  user_id: string;
}

export const modelHasEmployee = (employeeId) => (model: HasEmployeeId) => model.user_id === employeeId;

export const modelEmployeeInList = (employeeIds: string[]) => (model: HasEmployeeId) =>
  employeeIds.indexOf(model.user_id) !== -1;

export const getAuthenticatedUser = createSelector(
  getAuthenticatedUserId,
  getEmployeeEntities,
  (authenticatedUserId, employeeEntities) => employeeEntities[authenticatedUserId],
);

export const getAuthenticatedUserLocale = createSelector(getAuthenticatedUser, (user) => {
  if (!user) {
    return null;
  }

  return user.locale;
});
