interface BaseSortable {
  id: string;
  created?: string | null;
}

type MixedSortable =
  | (BaseSortable & {
      order: string;
    })
  | (BaseSortable & {
      weight: string;
    });

export const defaultSortingHelper = (a: MixedSortable, b: MixedSortable) => {
  const orderValueA = 'order' in a ? Number(a.order) : Number(a.weight);
  const orderValueB = 'order' in b ? Number(b.order) : Number(b.weight);

  // Sort on order/weight first
  if (orderValueA !== orderValueB) {
    return orderValueA - orderValueB;
  }

  // If order/weight is the same, sort on created date
  if (!!a.created && !!b.created && a.created !== b.created) {
    return a.created.localeCompare(b.created);
  }

  // Fall back to id
  return a.id.localeCompare(b.id);
};

export const sortByDefault = <T extends MixedSortable>(items: T[]): T[] =>
  items.sort((a, b) => defaultSortingHelper(a, b));
