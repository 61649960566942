import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { EMPTY, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, switchMapTo, take, tap } from 'rxjs/operators';

import { WindowRef } from '../../shared/fixed/window';
import { getAuthenticatedAccountId } from '../auth/auth.service';
import { getPermissionState, hasPermission } from '../auth/permission.helper';
import { AppState } from '../index';
import { AttachmentApi } from '../orm/attachment.api';
import { AccountAction } from './account.action';
import { AccountApi } from './account.api';
import { AccountModel, AccountState, CancelAccountRequest } from './account.model';

@Injectable()
export class AccountService {
  public state: Observable<AccountState>;

  private snapshot: AccountState;

  public constructor(
    private store: Store<AppState>,
    private api: AccountApi,
    private attachmentApi: AttachmentApi,
    private _window: WindowRef,
  ) {
    this.state = store.select((state) => state.account);
    this.state.subscribe((state) => (this.snapshot = state));
  }

  public load() {
    this.store.dispatch(AccountAction.fetch());

    return this.api.fetch().pipe(
      tap((response) => this.store.dispatch(AccountAction.fetchSucceeded(response))),
      catchError((response) => {
        this.store.dispatch(AccountAction.fetchFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public patch(data) {
    return this.store.select(getAuthenticatedAccountId).pipe(
      take(1),
      mergeMap((accountId) => {
        if (!accountId) {
          return observableThrowError('Account id missing');
        }

        this.store.dispatch(AccountAction.patch(accountId, data));

        return this.api.patch(accountId, data).pipe(
          map((response) => {
            this.store.dispatch(AccountAction.patchSucceeded(response));
            return response;
          }),
          catchError((response) => {
            this.store.dispatch(AccountAction.patchFailed(response));
            return observableThrowError(response);
          }),
        );
      }),
    );
  }

  public fetchInvoiceSettings() {
    if (this.account().account.send_invoice_to_reseller) {
      return EMPTY;
    }

    this.store.dispatch(AccountAction.fetchInvoiceSettings());

    return this.api.fetchInvoiceSettings().pipe(
      map((response) => {
        this.store.dispatch(AccountAction.fetchInvoiceSettingsSucceeded(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AccountAction.fetchInvoiceSettingsFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public patchInvoiceSettings(data) {
    return this.store.select(getAuthenticatedAccountId).pipe(
      take(1),
      mergeMap((accountId) => {
        if (!accountId) {
          return observableThrowError('Account id missing');
        }

        this.store.dispatch(AccountAction.patchInvoiceSettings(accountId, data));

        return this.api.patchInvoiceSettings(data).pipe(
          map((response) => {
            this.store.dispatch(AccountAction.patchInvoiceSettingsSucceeded(response));
            return response;
          }),
          catchError((response) => {
            this.store.dispatch(AccountAction.patchInvoiceSettingsFailed(response));
            return observableThrowError(response);
          }),
        );
      }),
    );
  }

  public account() {
    return this.snapshot;
  }

  public loading(): Observable<boolean> {
    return this.state.pipe(map((state) => state.loading));
  }

  public loadFiles() {
    return this.store.select(getAuthenticatedAccountId).pipe(
      take(1),
      switchMap((accountId) =>
        this.api.loadFiles(accountId, AccountAction.loadFiles()).pipe(
          map((response) => {
            this.store.dispatch(AccountAction.loadFilesSuccess(response));
            return response;
          }),
          catchError((response) => {
            this.store.dispatch(AccountAction.loadFilesFailed(response));
            return observableThrowError(response);
          }),
        ),
      ),
    );
  }

  public download(attachmentId: string, fileName: string) {
    return this.attachmentApi.download(attachmentId, fileName);
  }

  public saveFile(data) {
    if (data.id) {
      return this.updateFile(data);
    }

    return this.addFile(data);
  }

  public addFile(data): Observable<any> {
    return this.store.select(getAuthenticatedAccountId).pipe(
      take(1),
      switchMap((accountId) =>
        this.api.addFile(accountId, data, AccountAction.addFile(data)).pipe(
          map((response) => {
            this.store.dispatch(AccountAction.addFileSuccess(response));
            return observableOf(response);
          }),
          catchError((response) => {
            this.store.dispatch(AccountAction.addFileFailed(response));
            return observableThrowError(response);
          }),
        ),
      ),
    );
  }

  public updateFile(data): Observable<any> {
    return this.api.updateFile(data.id, data, AccountAction.editFile(data)).pipe(
      map((response) => {
        this.store.dispatch(AccountAction.editFileSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(AccountAction.editFileFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public deleteFile(attachmentId: string) {
    return this.attachmentApi.delete(attachmentId, AccountAction.deleteFile(attachmentId)).pipe(
      map((response) => {
        this.store.dispatch(AccountAction.deleteFileSuccess(attachmentId));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(AccountAction.deleteFileFailed(attachmentId));
        return observableThrowError(response);
      }),
    );
  }

  public cancelAccount(data: CancelAccountRequest) {
    return this.api.cancelAccount(data, AccountAction.cancelAccount(data)).pipe(
      map((response) => {
        this.store.dispatch(AccountAction.cancelAccountSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(AccountAction.cancelAccountFailed());
        return observableThrowError(response);
      }),
    );
  }

  public fetchUpdates() {
    return this.api.fetchUpdates().pipe(
      map((response) => response),
      catchError((response) => observableThrowError(response)),
    );
  }

  public fetchInvoices() {
    return this.api.fetchInvoices().pipe(
      map((response) => response),
      catchError((response) => observableThrowError(response)),
    );
  }

  public fetchExpiredInvoices() {
    return this.api.fetchExpiredInvoices().pipe(
      map((response) => response),
      catchError((response) => observableThrowError(response)),
    );
  }

  public toggleSupportAccess(data) {
    return this.api.toggleSupportAccess(data, AccountAction.toggleSupportAccess()).pipe(
      map((response) => {
        this.store.dispatch(AccountAction.toggleSupportAccessSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AccountAction.toggleSupportAccessFailed());
        return observableThrowError(response);
      }),
    );
  }

  public fetchNotificationSettings(userId) {
    return this.api.fetchNotificationSettings(userId, AccountAction.fetchNotificationSettings()).pipe(
      map((response) => {
        this.store.dispatch(AccountAction.fetchNotificationSettingsSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AccountAction.fetchNotificationSettingsFailed());
        return observableThrowError(response);
      }),
    );
  }

  public updateNotificationSettings(userId, data) {
    return this.api.updateNotificationSettings(userId, data, AccountAction.updateNotificationSettings()).pipe(
      map((response) => {
        this.store.dispatch(AccountAction.updateNotificationSettingsSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AccountAction.updateNotificationSettingsFailed());
        return observableThrowError(response);
      }),
    );
  }

  public getUnpaidInvoices() {
    return this.load().pipe(
      switchMapTo(this.store.select(getPermissionState)),
      switchMap((permissionState) => {
        const manageAccount = hasPermission(
          {
            permissions: ['Manage account'],
            userId: 'me',
            departments: 'any',
          },
          permissionState,
        );

        if (!manageAccount) {
          return observableOf([]);
        }

        return this.fetchInvoices();
      }),
      take(1),
      map((invoices) => {
        if (!invoices) {
          return [];
        }

        return invoices.filter((invoice) => invoice.status !== 'paid');
      }),
    );
  }

  public importFromChargeBee() {
    return this.api.importChargeBee();
  }

  public pushToChargebee() {
    return this.api.pushChargebee();
  }
}

export const getAccountState = (appState: AppState): AccountState => appState.account;
export const getAccount = createSelector(getAccountState, (state) => state.account);

export const getAccountManagerId = createSelector(getAccount, (state) => {
  if (state && state.user_id) {
    return state.user_id;
  } else {
    return null;
  }
});

export const getAccountCocInSchedule = createSelector(getAccount, (account) => account?.coc_in_schedule);

export const getAccountSubscription = createSelector(getAccountState, (account) => {
  if (!account || !account.subscription) {
    return null;
  }
  return account.subscription;
});

export const accountLoaded = createSelector(getAccount, (account) => !!(account && account.id));

export const getDefaultPermissionGroup = compose((account) => account.group_id, getAccount);

export const getAccountLocale = createSelector(getAccount, (account: AccountModel) => {
  if (!account) {
    return null;
  }
  return account.locale;
});

export const getAccountTimezone = createSelector(getAccount, (account: AccountModel) => account?.time_zone);

export const getEmployeeSortDirection = createSelector(getAccount, (account) => {
  if (!account || !account.user_sortdirection) {
    return 'ASC';
  }
  return account.user_sortdirection;
});

export const getEmployeeSortField = createSelector(getAccount, (account) => {
  if (!account) {
    return 'first_name';
  }
  return account.user_sortfield;
});

export const getAccountFiles = createSelector(getAccount, (account) => {
  if (!account.Files) {
    return [];
  }

  return sortBy(account.Files, 'created');
});

export const getAccountAttachment = (attachmentId) =>
  compose((account: AccountModel) => {
    if (!account.Files) {
      return void 0;
    }

    return account.Files[attachmentId];
  }, getAccount);
