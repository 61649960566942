import { Injectable } from '@angular/core';
import { ChargebeeHostedPageResponse } from '@app/shared/billing/billing.model';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../interfaces';
import { assignSchemaEntity } from '../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../shared/entity.helper';
import { AccountFileSchema } from '../shared/schemas';

@Injectable()
export class AccountApi {
  private endpoint = 'accounts/';
  private gtmEndpoint = 'gtm_info';

  public constructor(private gateway: ApiGateway) {}

  public fetch(): Observable<any> {
    const params = { include: 'Currency,AccountType,Subscription' };

    return this.gateway.get(this.endpoint, { params });
  }

  public fetchTagManagerInfo(): Observable<any> {
    return this.gateway.get(this.gtmEndpoint, undefined);
  }

  public patch(accountId, data): Observable<any> {
    const postData = Object.assign({}, data, { id: accountId });

    return this.gateway.put(this.endpoint + accountId, postData);
  }

  public fetchInvoiceSettings() {
    return this.gateway.get(this.endpoint + 'invoice_settings');
  }

  public patchInvoiceSettings(data): Observable<any> {
    return this.gateway.post(this.endpoint + 'invoice_settings', data);
  }

  public loadFiles(accountId, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + accountId + '/files', undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('Attachment', res)),
      map((data) => normalize(data, arrayOf(AccountFileSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public addFile(employeeId, attachmentData, dispatchStart?: Action) {
    return this.gateway.post(this.endpoint + employeeId + '/files', attachmentData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Attachment', res)),
      map((data) => normalize(data, AccountFileSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public updateFile(attachmentId, data, dispatchStart?: Action) {
    return this.gateway.put('attachments/' + attachmentId, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Attachment', res)),
      map((attachment) => normalize(attachment, AccountFileSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public cancelAccount(message, dispatchStart?: Action) {
    return this.gateway.post('subscriptions/cancel', message, undefined, dispatchStart);
  }

  public fetchUpdates() {
    return this.gateway.get('system-updates');
    //.map( res => res.map( x => x.Updates ) );
  }

  public fetchInvoices() {
    return this.gateway.get('invoices').pipe(map((res) => res.map((x) => x.Invoice)));
  }

  public fetchExpiredInvoices() {
    return this.gateway.get('invoices/expired').pipe(map((res) => res.map((x) => x.Invoice)));
  }

  public toggleSupportAccess(data, dispatchStart?: Action) {
    return this.gateway.post(this.endpoint + 'toggle_support_access', data, undefined, dispatchStart);
  }

  public updateNotificationSettings(userId, data, dispatchStart?: Action) {
    return this.gateway.post('notification_settings/edit/' + userId, data, undefined, dispatchStart);
  }

  public fetchNotificationSettings(userId, dispatchStart?: Action) {
    return this.gateway.get('notification_settings/' + userId, undefined, dispatchStart);
  }

  public fetchInvoicePdf(invoiceId) {
    return this.gateway.get('invoices/' + invoiceId + '/pdf');
  }

  public fetchChargebeePaymentPage(): Observable<ChargebeeHostedPageResponse> {
    return this.gateway.get('billing/chargebee/collect_now');
  }

  public importChargeBee() {
    return this.gateway.post('chargebee/pull_customer', undefined, undefined);
  }

  public pushChargebee() {
    return this.gateway.post('chargebee/push_customer', undefined, undefined);
  }
}
