/* eslint-disable max-lines */
import every from 'lodash-es/every';
import includes from 'lodash-es/includes';
import intersection from 'lodash-es/intersection';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import mapValues from 'lodash-es/mapValues';
import some from 'lodash-es/some';
import { createSelector } from 'reselect';

import { format } from '../../shared/date.helper';
import { TreeviewItem } from '../../shared/ngx-treeview/treeview-item';
import { getAccountManagerId } from '../account/account.service';
import { EmployeeModel } from '../orm/employee/employee.model';
import {
  canEmployeeRequestAbsence,
  employeeActiveInPeriod,
  getEmployeeEntities,
  getEmployeesWithSelectedTeamDepartment,
} from '../orm/employee/employee.service';
import { PermissionOption } from '../orm/permission/permission.model';
import { TeamModel, TeamType } from '../orm/team/team.model';
import {
  getTeamEntities,
  getTeamsWithoutFlexpool,
  getVisibleTeams,
  getVisibleTeamsGroupedByDepartment,
} from '../orm/team/team.service';
import { getSelectedDepartmentIds } from '../selected-departments/selected-departments.service';
import { getAppState } from '../shared/entity.helper';
import { PermissionState } from './auth.model';
import {
  getAuthenticatedAvailablePermissions,
  getAuthenticatedDepartmentIds,
  getAuthenticatedPermissions,
  getAuthenticatedUserId,
} from './auth.service';

/**
 * Permissions that should have had "own" in its name
 * @type {string[]}
 */
const userLevelPermissions = ['View vacation hours', 'Create comment', 'Request exchange'];

const defaultOwnPermissions = ['View own roster', 'View own public uploads', 'View own availability'];

export interface PermissionCheck {
  permissions: PermissionOption;
  userId?: string | number | 'me';
  departments?: string[] | string;
  extendedPermissions?: PermissionOption;
}

export const getPermissionState = createSelector(
  [
    getAuthenticatedUserId,
    (state) => getAccountManagerId(state),
    getAuthenticatedDepartmentIds,
    getAuthenticatedPermissions,
    getAuthenticatedAvailablePermissions,
    getSelectedDepartmentIds,
  ],
  (userId, accountManager, departments, permissions, availablePermissions, selectedDepartments): PermissionState => ({
    userId: userId || null,
    isAccountManager: userId === accountManager && !!userId,
    permissions: permissions,
    departments: departments || [],
    selectedDepartments: selectedDepartments,
    availablePermissions,
  }),
);

export const getEmployeeTeamDepartments = createSelector(
  getEmployeeEntities,
  getTeamEntities,
  (employeeEntities, teamEntities) =>
    mapValues(employeeEntities, (employee) => {
      if (employee && employee.Team && teamEntities) {
        return employee.Team.map((team) => (teamEntities[team] && teamEntities[team].department_id) || null);
      }
    }),
);

export const getEmployeeTeamDepartmentsWithoutFlexpool = createSelector(
  getEmployeeEntities,
  getTeamsWithoutFlexpool,
  (employeeEntities, teamEntities) =>
    mapValues(employeeEntities, (employee) => {
      if (employee && employee.Team && teamEntities) {
        return employee.Team.filter(
          (team) => teamEntities[team]?.department_id && teamEntities[team].type !== TeamType.FLEXPOOL,
        ).map((team) => teamEntities[team].department_id);
      }
    }),
);

export const getEmployeeTeamDepartmentsWithoutHiddenTeams = createSelector(
  getEmployeeEntities,
  getTeamEntities,
  (employeeEntities, teamEntities) =>
    mapValues(employeeEntities, (employee) => {
      if (!employee.Team) return [];

      return employee.Team.filter(
        (team) => teamEntities[team]?.department_id && teamEntities[team].type !== TeamType.HIDDEN,
      ).map((team) => teamEntities[team].department_id);
    }),
);

export const getTeamDepartmentsForEmployee = (userId: string) =>
  createSelector(getEmployeeTeamDepartmentsWithoutHiddenTeams, (teamDepartments) => teamDepartments[userId] || []);

function isSuperUser(state: PermissionState) {
  return state.userId === 'god' || state.isAccountManager;
}

/**
 * Checks if for at least one of the given permissions the user belongs
 * to at least 1 of the departments.
 * @return boolean
 * @param check
 * @param state
 */
export const hasPermission = (check: PermissionCheck, state: PermissionState): boolean => {
  if (!state.userId) {
    return false;
  }

  //if there are no permissions to check against
  if (isEmpty(check.permissions)) {
    return true;
  }

  const requiredPermissions = valueToArray(check.permissions);

  if (isSuperUser(state)) {
    if (requiredPermissions.some((permission) => state.availablePermissions.includes(permission))) {
      return true;
    }
  }

  const checkDepartments = departmentsToCheck(state, check.departments);
  const userId = userToCheck(check, state);

  return some(requiredPermissions, (requiredPermission) =>
    checkPermission(state, requiredPermission, userId, checkDepartments),
  );
};

/**
 * Check if for all given permissions the user
 * belongs to at least 1 of the departments.
 * @param permissionCheck
 * @param permissionState
 * @return boolean
 */
export const hasAllPermissions = (check: PermissionCheck, state: PermissionState) => {
  if (!state.userId) {
    return false;
  }

  //if there are no permissions to check against
  if (isEmpty(check.permissions)) {
    return true;
  }

  const requiredPermissions = valueToArray(check.permissions);

  if (isSuperUser(state)) {
    if (requiredPermissions.every((permission) => state.availablePermissions.includes(permission))) {
      return true;
    }
  }

  const checkDepartments = departmentsToCheck(state, check.departments);
  const userId = userToCheck(check, state);

  return every(requiredPermissions, (requiredPermission) =>
    checkPermission(state, requiredPermission, userId, checkDepartments),
  );
};

export const hasAllPermissionsForReports = (check: PermissionCheck, state: PermissionState) => {
  if (!state.userId) {
    return false;
  }

  //if there are no permissions to check against
  if (isEmpty(check.permissions) && isEmpty(check.extendedPermissions)) {
    return true;
  }

  const requiredPermissions = valueToArray(check.permissions);
  const extendedPermissions = valueToArray(check.extendedPermissions);

  if (isSuperUser(state)) {
    if (requiredPermissions.every((permission) => state.availablePermissions.includes(permission))) {
      return true;
    }
  }

  const checkDepartments = departmentsToCheck(state, check.departments);
  const userId = userToCheck(check, state);

  return checkDepartments.some((department) => {
    const hasExtendedPermission =
      extendedPermissions.length === 0 ||
      extendedPermissions.some((extendedPermission: string) =>
        checkPermission(state, extendedPermission, userId, [department]),
      );
    return (
      hasExtendedPermission &&
      requiredPermissions.every((requiredPermission: string) =>
        checkPermission(state, requiredPermission, userId, [department]),
      )
    );
  });
};

/**
 * Returns a list with department Ids for which the user has at
 * least one of the provided permissions.
 * @param permissionCheck
 * @param permissionState
 * @return array of department ids
 */
export const permissionDepartments = (check: PermissionCheck, state: PermissionState): string[] => {
  const checkDepartments = departmentsToCheck(state, check.departments);

  if (isSuperUser(state)) {
    const requiredPermissions = valueToArray(check.permissions);
    if (requiredPermissions.some((permission) => state.availablePermissions.includes(permission))) {
      return checkDepartments;
    }
  }

  //if there are no permissions to check against
  if (isEmpty(check.permissions)) {
    return checkDepartments;
  }

  return checkDepartments.filter((departmentId) =>
    hasPermission(
      {
        permissions: check.permissions,
        userId: check.userId,
        departments: [departmentId],
      },
      state,
    ),
  );
};

/**
 * Returns a boolean, whether the user has at least 1 of the
 * provided permissions for all departments that were provided.
 * @param permissionCheck
 * @param permissionState
 * @return boolean
 */
export const hasPermissionForAllDepartments = (check: PermissionCheck, state: PermissionState) => {
  if (isSuperUser(state)) {
    const requiredPermissions = valueToArray(check.permissions);
    if (requiredPermissions.some((permission) => state.availablePermissions.includes(permission))) {
      return true;
    }
  }

  //if there are no permissions to check against
  if (isEmpty(check.permissions)) {
    return true;
  }

  const checkDepartments = departmentsToCheck(state, check.departments);

  return every(checkDepartments, (departmentId) =>
    hasPermission(
      {
        permissions: check.permissions,
        userId: check.userId,
        departments: [departmentId],
      },
      state,
    ),
  );
};

function valueToArray(value: string | string[]): string[] {
  if (isEmpty(value)) {
    return [];
  }

  if (isArray(value)) {
    return value;
  }

  if (value.includes(',')) {
    return value
      .replace(/\[|]|'/gi, '')
      .split(',')
      .map((part) => part.trim());
  } else {
    return [value];
  }
}

/**
 * Returns an array of departments that should be checked
 * @param permissionState
 * @param departments
 * @returns array
 */
function departmentsToCheck(state: PermissionState, departments: string | string[]): string[] {
  if (isEmpty(departments)) {
    return state.selectedDepartments;
  }

  if (departments === 'any') {
    return state.departments;
  }

  return valueToArray(departments);
}

/**
 * Returns which user should be checked
 * @param permissionCheck
 * @param permissionState
 * @returns string
 */
function userToCheck(check: PermissionCheck, state: PermissionState) {
  if (!check.userId) {
    return null;
  }

  if (check.userId === 'me') {
    return state.userId;
  }

  return check.userId;
}

/**
 * Check whether the requiredPermission is one of the own permissions
 * the user has always permission for.
 * @param requiredPermission
 * @returns boolean
 */
const isOwn = (requiredPermission: string) => {
  if (includes(requiredPermission, 'own')) {
    return true;
  }

  return includes(userLevelPermissions, requiredPermission);
};

/**
 * Checks if at least one of the values is in the list
 * @param list
 * @param values
 * @return boolean
 */
const containsAny = (list, values): boolean =>
  some(values, function (value) {
    return includes(list, value);
  });

/**
 * Checks for the given permission if the user belongs
 * to at least one department that were provided.
 * @param permissionState
 * @param requiredPermission
 * @param userId
 * @param departments
 * @returns boolean
 */
function checkPermission(
  state: PermissionState,
  requiredPermission: string,
  userId: string | number,
  departments: string[],
) {
  // is the current permission a default permission?
  if (includes(defaultOwnPermissions, requiredPermission)) {
    return checkOwn(userId, state);
  }

  // is the current permission one of the required permissions?
  if (isEmpty(state.permissions[requiredPermission])) {
    return false;
  }
  //check departments
  if (!containsAny(departments, state.permissions[requiredPermission])) {
    return false;
  }

  //check for own string in permission name value
  if (isOwn(requiredPermission)) {
    return checkOwn(userId, state);
  }

  return true;
}

/**
 * Check whether the given userId is from the user itself.
 * If so, the user has permission to all own permissions.
 * If not, the user does not have permission to all own permissions.
 * @param userId
 * @param permissionState
 * @returns boolean
 */
function checkOwn(userId: string | number, state) {
  if (isEmpty(userId)) {
    return false;
  }

  return state.userId === userId;
}

/**
 * get all active employees the current user has permissions for and is in a team
 *
 * @param minDate
 * @param maxDate
 * @param permissions
 * @param departmentIds
 */
export const getEmployeesForPeriod = (
  permissions: PermissionOption,
  minDate: string = format(new Date(), 'yyyy-MM-dd'),
  maxDate: string = format(new Date(), 'yyyy-MM-dd'),
  departmentIds?: string | string[],
) =>
  createSelector(
    getAuthenticatedUserId,
    getPermissionState,
    getAppState,
    (authenticatedEmployeeId, permissionState, appState): EmployeeModel[] => {
      const authDepartments = permissionDepartments(
        {
          permissions: permissions,
          departments: departmentIds,
        },
        permissionState,
      );

      const canManageOwn = hasPermission(
        {
          userId: 'me',
          permissions: permissions,
          departments: departmentIds,
        },
        permissionState,
      );

      const ensureUserId = canManageOwn ? authenticatedEmployeeId : void 0;
      const employees = getEmployeesWithSelectedTeamDepartment(authDepartments, ensureUserId, false);
      const activeFilter = employeeActiveInPeriod(minDate, maxDate);

      return employees(appState).filter(activeFilter);
    },
  );

export const getAuthDepartments = (permissions: PermissionOption, departmentIds?: string | string[]) =>
  createSelector(
    getPermissionState,
    getAuthenticatedUserId,
    (permissionState: PermissionState, authenticatedEmployeeId: string) => {
      const authDepartments = permissionDepartments(
        {
          permissions: permissions,
          departments: departmentIds,
        },
        permissionState,
      );

      const manageOwn = hasPermission(
        {
          userId: 'me',
          permissions: permissions,
          departments: departmentIds,
        },
        permissionState,
      );

      const ensureUserId = manageOwn ? authenticatedEmployeeId : void 0;

      return {
        ensureUserId,
        authDepartments,
      };
    },
  );

export const getEmployeesForAbsentee = (
  date: string = format(new Date(), 'yyyy-MM-dd'),
  ensureUserId: string,
  departmentIds?: string[],
) =>
  createSelector(
    getVisibleTeams(false),
    getEmployeesWithSelectedTeamDepartment(departmentIds, ensureUserId, false),
    (visibleTeams, employees: EmployeeModel[]): EmployeeModel[] => {
      const filteredEmployees = employees.filter(
        (employee) =>
          visibleTeams.some((team) => employee.Team.includes(team.id)) && canEmployeeRequestAbsence(date)(employee),
      );

      return filteredEmployees;
    },
  );

/**
 * get all active employees the current user has permissions for and is in a team
 * of a specific department
 *
 * @param permissions
 * @param minDate
 * @param maxDate
 * @param departmentId
 * @param employeeIdsToDisable
 * @param includeFlexpool
 */
export const getEmployeeOptionsPerTeamForDepartment = (
  permissions,
  minDate: string = format(new Date(), 'yyyy-MM-dd'),
  maxDate: string = format(new Date(), 'yyyy-MM-dd'),
  departmentId: string,
  employeeIdsToDisable = [],
  includeFlexpool = false,
) =>
  createSelector(
    getEmployeesForPeriod(permissions, minDate, maxDate, departmentId),
    getVisibleTeamsGroupedByDepartment(includeFlexpool),
    (employees: EmployeeModel[], teamsPerDepartment): TreeviewItem[] => {
      const teams = teamsPerDepartment[departmentId] || [];
      const mappedEmployees = employees.map((employee) => ({
        ...employee,
        optionDisabled: employeeIdsToDisable.includes(employee.id),
      }));
      return (
        teams
          .map((team: TeamModel) => convertTeamToTreeView(team, mappedEmployees))
          //remove empty teams
          .filter((team: TreeviewItem) => team.children && team.children.length > 0)
      );
    },
  );

/**
 * get all active employees the current user has permissions for and is in a team
 * of a specific department
 *
 * @param permissions
 * @param minDate
 * @param maxDate
 * @param departmentId
 * @param employeeIdsToDisable
 * @param includeFlexpool
 */
export const getEmployeeOptionsPerTeamForDepartmentTable = (
  permissions,
  minDate: string = format(new Date(), 'yyyy-MM-dd'),
  maxDate: string = format(new Date(), 'yyyy-MM-dd'),
  departmentId: string,
  employeeIdsToDisable = [],
  includeFlexpool = false,
) =>
  createSelector(
    getEmployeesForPeriod(permissions, minDate, maxDate, departmentId),
    getVisibleTeamsGroupedByDepartment(includeFlexpool),
    (employees: EmployeeModel[], teamsPerDepartment): EmployeeModel[] => {
      const teams = teamsPerDepartment[departmentId] || [];

      return teams
        .map((team: TeamModel) =>
          employees
            .filter((employee) => employee.Team?.includes(team.id))
            .map((employee) => ({
              ...employee,
              optionDisabled: employeeIdsToDisable.includes(employee.id),
              team,
            })),
        )
        .flat();
    },
  );

/**
 * get all active employees the current user has permissions for and is in a team
 *
 * @param permissions
 * @param minDate
 * @param maxDate
 */
export const getEmployeeOptionsPerTeam = (
  permissions,
  minDate: string = format(new Date(), 'yyyy-MM-dd'),
  maxDate: string = format(new Date(), 'yyyy-MM-dd'),
) =>
  createSelector(
    getEmployeesForPeriod(permissions, minDate, maxDate),
    getVisibleTeams(),
    (employees: EmployeeModel[], teams: TeamModel[]): TreeviewItem[] =>
      teams.map((team) => convertTeamToTreeView(team, employees)).filter((item) => item.children),
  );

export const getEmployeeOptionsPerTeamForSelectedDepartments = (
  permissions,
  minDate: string = format(new Date(), 'yyyy-MM-dd'),
  maxDate: string = format(new Date(), 'yyyy-MM-dd'),
  includeFlexpool = false,
) =>
  createSelector(
    getEmployeesForPeriod(permissions, minDate, maxDate),
    getVisibleTeams(includeFlexpool),
    getSelectedDepartmentIds,
    (employees: EmployeeModel[], teams: TeamModel[], departmentIds: string[]): TreeviewItem[] =>
      teams
        .filter((team) => departmentIds.includes(team.department_id))
        .map((team) => convertTeamToTreeView(team, employees))
        .filter((item) => item.children),
  );

function convertTeamToTreeView(team: TeamModel, employees: EmployeeModel[]): TreeviewItem {
  // employees in team
  const children = employees
    .filter((employee: EmployeeModel) => {
      if (!employee.Team) {
        return false;
      }
      return employee.Team.indexOf(team.id) !== -1;
    })
    .map(
      (employee) =>
        new TreeviewItem({
          text: employee.name,
          value: employee.id,
          disabled: employee.optionDisabled,
        }),
    );

  return new TreeviewItem({
    text: team.name,
    value: team.id,
    children,
  });
}

export const canViewIntegrationReport = (integration, permissionState) => {
  const check: PermissionCheck = {
    userId: 'me',
    permissions: 'View reports',
    departments: 'any',
  };

  const departmentIds = permissionDepartments(check, permissionState);

  if (!integration.hasOwnProperty('ApiUsersDepartment')) {
    return false;
  }

  const apiUsersDepartmentIds = integration.ApiUsersDepartment.map((mapping) => mapping.department_id);
  const intersect = intersection(apiUsersDepartmentIds, departmentIds);

  return intersect.length > 0;
};

export const canPrintTimesheetStatement = (permissionState: PermissionState, departments: string[] | string = 'any') =>
  hasAllPermissions(
    {
      permissions: [
        'View all timesheets',
        'View absentee',
        'View plus min hours',
        'View time off balances',
        'View user details',
      ],
      departments,
    },
    permissionState,
  );
