import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiGateway } from '../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../interfaces';
import { AuthRequest, AuthResponse } from './auth.model';

@Injectable()
export class AuthApi {
  private endpoint = 'auth/';
  private passwordEndpoint = 'password/';

  public constructor(private gateway: ApiGateway) {}

  public login(auth: AuthRequest, dispatchStart?: Action): Observable<AuthResponse> {
    return this.gateway.post(this.endpoint + 'login', auth, undefined, dispatchStart);
  }

  public logout(dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + 'logout', null, undefined, dispatchStart);
  }

  public status(): Observable<any> {
    return this.gateway.get(this.endpoint + 'login');
  }

  public forgotPassword(payload, dispatchStart?: Action) {
    return this.gateway.post(this.passwordEndpoint + 'email', payload, undefined, dispatchStart);
  }

  public changeEmail(payload, dispatchStart?: Action) {
    return this.gateway.put(this.endpoint + 'email', payload, undefined, dispatchStart);
  }

  public changePassword(payload, dispatchStart?: Action) {
    return this.gateway.put(this.passwordEndpoint, payload, undefined, dispatchStart);
  }

  public resetPassword(payload, dispatchStart?: Action) {
    return this.gateway.post(this.passwordEndpoint + 'reset', payload, undefined, dispatchStart);
  }

  public signUp(payload, dispatchStart?: Action) {
    return this.gateway.post('signup', payload, undefined, dispatchStart);
  }

  public verifySignUp(payload, dispatchStart?: Action) {
    return this.gateway.put('signup', payload, undefined, dispatchStart);
  }

  public sessions() {
    return this.gateway.get(this.endpoint + 'sessions');
  }

  public removeSessions(userId, payload) {
    return this.gateway.post(this.endpoint + userId + '/signout', payload);
  }

  public fetchCountries(language: string, observeResponse: boolean) {
    const options = observeResponse ? { observe: 'response' } : undefined;
    return this.gateway.get('countries?locale=' + language, options);
  }

  public changePinCode() {
    return this.gateway.post('pin/generate', null);
  }

  public getPinCode() {
    return this.gateway.get('pin');
  }

  public sendVerificationMail() {
    return this.gateway.post('users/verification/send', null);
  }

  public uptime() {
    return this.gateway.get('up', { responseType: 'text' }, undefined, false);
  }
}
