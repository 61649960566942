import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../index';
import { SelectedDepartmentsAction } from './selected-departments.action';

@Injectable()
export class SelectedDepartmentsService {
  constructor(private store: Store<AppState>) {}

  toggleDepartment(departmentId) {
    this.store.dispatch(SelectedDepartmentsAction.toggleDepartment(departmentId));
  }

  toggleDepartments(departmentIds) {
    this.store.dispatch(SelectedDepartmentsAction.toggleDepartments(departmentIds));
  }

  selectDepartment(departmentId) {
    this.store.dispatch(SelectedDepartmentsAction.selectDepartment(departmentId));
  }

  selectDepartments(departmentIds) {
    this.store.dispatch(SelectedDepartmentsAction.selectDepartments(departmentIds));
  }
}

export const getSelectedDepartmentIds = (state: AppState) => state.selectedDepartments;

interface HasDepartmentId {
  department_id?: string;
}

export const departmentFilter = (selectedDepartments: string[]) => (data: HasDepartmentId) => selectedDepartments.indexOf(data.department_id) !== -1;
