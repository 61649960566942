import { UnsafeAction as Action } from '../interfaces';
import { SubscriptionModel } from '../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { CancelAccountRequest } from './account.model';

const ACTION_PREFIX = '[Account]';
export const accountActionType = {
  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCEEDED: `${ACTION_PREFIX} Fetch Succeeded`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  PATCH: `${ACTION_PREFIX} Patch account`,
  PATCH_SUCCEEDED: `${ACTION_PREFIX} Patch account succeeded`,
  PATCH_FAILED: `${ACTION_PREFIX} Patch account failed`,

  FETCH_SUBSCRIPTION_PLAN: `${ACTION_PREFIX} Fetch Subscription Plan`,
  FETCH_SUBSCRIPTION_PLAN_SUCCEEDED: `${ACTION_PREFIX} Fetch Subscription Plan Succeeded`,
  FETCH_SUBSCRIPTION_PLAN_FAILED: `${ACTION_PREFIX} Fetch Subscription Plan Failed`,

  FETCH_INVOICE_SETTINGS: `${ACTION_PREFIX} Fetch Invoice Settings`,
  FETCH_INVOICE_SETTINGS_SUCCEEDED: `${ACTION_PREFIX} Fetch Invoice Settings Succeeded`,
  FETCH_INVOICE_SETTINGS_FAILED: `${ACTION_PREFIX} Fetch Invoice Settings Failed`,

  PATCH_INVOICE_SETTINGS: `${ACTION_PREFIX} Patch Invoice Settings `,
  PATCH_INVOICE_SETTINGS_SUCCEEDED: `${ACTION_PREFIX} Patch Invoice Settings succeeded`,
  PATCH_INVOICE_SETTINGS_FAILED: `${ACTION_PREFIX} Patch Invoice Settings failed`,

  LOAD_FILES: `${ACTION_PREFIX} Load files`,
  LOAD_FILES_SUCCESS: `${ACTION_PREFIX} Load files Success`,
  LOAD_FILES_FAILED: `${ACTION_PREFIX} Load files Failed`,

  ADD_FILE: `${ACTION_PREFIX} Add file`,
  ADD_FILE_SUCCESS: `${ACTION_PREFIX} Add file Success`,
  ADD_FILE_FAILED: `${ACTION_PREFIX} Add file Failed`,

  EDIT_FILE: `${ACTION_PREFIX} Edit file`,
  EDIT_FILE_SUCCESS: `${ACTION_PREFIX} Edit file Success`,
  EDIT_FILE_FAILED: `${ACTION_PREFIX} Edit file Failed`,

  DELETE_FILE: `${ACTION_PREFIX} Delete file`,
  DELETE_FILE_SUCCESS: `${ACTION_PREFIX} Delete file Success`,
  DELETE_FILE_FAILED: `${ACTION_PREFIX} Delete file Failed`,

  CANCEL_ACCOUNT: `${ACTION_PREFIX} Cancel account`,
  CANCEL_ACCOUNT_SUCCESS: `${ACTION_PREFIX} Cancel account Success`,
  CANCEL_ACCOUNT_FAILED: `${ACTION_PREFIX} Cancel account Failed`,

  TOGGLE_SUPPORT_ACCESS: `${ACTION_PREFIX} Toggle support access account`,
  TOGGLE_SUPPORT_ACCESS_SUCCESS: `${ACTION_PREFIX} Toggle support access Success`,
  TOGGLE_SUPPORT_ACCESS_FAILED: `${ACTION_PREFIX} Toggle support access Failed`,

  FETCH_NOTIFICATION_SETTINGS: `${ACTION_PREFIX} Fetch notification settings`,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS: `${ACTION_PREFIX} Fetch notification settings success`,
  FETCH_NOTIFICATION_SETTINGS_FAILED: `${ACTION_PREFIX} Fetch notification settings failed`,

  UPDATE_NOTIFICATION_SETTINGS: `${ACTION_PREFIX} Update notification settings`,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS: `${ACTION_PREFIX} Update notification settings success`,
  UPDATE_NOTIFICATION_SETTINGS_FAILED: `${ACTION_PREFIX} Update notification settings failed`,

  SET_DEFAULT_PERMISSION_GROUP: `${ACTION_PREFIX} Set default permission group`,
  UPDATE_ACCOUNT_MANAGER: `${ACTION_PREFIX} Update account manager`,
};

export class AccountAction {
  static fetch(): Action {
    return {
      type: accountActionType.FETCH,
    };
  }

  static fetchSucceeded(response: any): Action {
    return {
      type: accountActionType.FETCH_SUCCEEDED,
      payload: response,
    };
  }

  static fetchFailed(response: any): Action {
    return {
      type: accountActionType.FETCH_FAILED,
      payload: response,
    };
  }

  static fetchSubscription(): Action {
    return {
      type: accountActionType.FETCH_SUBSCRIPTION_PLAN,
    };
  }

  static fetchSubscriptionSucceeded(response: SubscriptionModel): Action {
    return {
      type: accountActionType.FETCH_SUBSCRIPTION_PLAN_SUCCEEDED,
      payload: response,
    };
  }

  static fetchSubscriptionFailed(response: any): Action {
    return {
      type: accountActionType.FETCH_FAILED,
      payload: response,
    };
  }

  static patch(accountId: string, data: any): Action {
    return {
      type: accountActionType.PATCH,
      payload: {
        accountId,
        data,
      },
    };
  }

  static patchSucceeded(response: any): Action {
    return {
      type: accountActionType.PATCH_SUCCEEDED,
      payload: response,
    };
  }

  static patchFailed(response: any): Action {
    return {
      type: accountActionType.PATCH_FAILED,
      payload: response,
    };
  }

  static fetchInvoiceSettings(): Action {
    return {
      type: accountActionType.FETCH_INVOICE_SETTINGS,
    };
  }

  static fetchInvoiceSettingsSucceeded(response: any): Action {
    return {
      type: accountActionType.FETCH_INVOICE_SETTINGS_SUCCEEDED,
      payload: response,
    };
  }

  static fetchInvoiceSettingsFailed(response: any): Action {
    return {
      type: accountActionType.FETCH_INVOICE_SETTINGS_FAILED,
      payload: response,
    };
  }

  static patchInvoiceSettings(accountId: string, data: any): Action {
    return {
      type: accountActionType.PATCH_INVOICE_SETTINGS,
      payload: {
        accountId,
        data,
      },
    };
  }

  static patchInvoiceSettingsSucceeded(response: any): Action {
    return {
      type: accountActionType.PATCH_INVOICE_SETTINGS_SUCCEEDED,
      payload: response,
    };
  }

  static patchInvoiceSettingsFailed(response: any): Action {
    return {
      type: accountActionType.PATCH_INVOICE_SETTINGS_FAILED,
      payload: response,
    };
  }

  static loadFiles(): Action {
    return {
      type: accountActionType.LOAD_FILES,
    };
  }

  static loadFilesSuccess(response) {
    return {
      type: accountActionType.LOAD_FILES_SUCCESS,
      payload: response,
    };
  }

  static loadFilesFailed(err) {
    return {
      type: accountActionType.LOAD_FILES_FAILED,
      payload: err,
    };
  }

  static deleteFile(attachmentId: string) {
    return {
      type: accountActionType.DELETE_FILE,
      payload: {
        attachmentId,
      },
    };
  }

  static deleteFileSuccess(attachmentId: string) {
    return {
      type: accountActionType.DELETE_FILE_SUCCESS,
      payload: {
        attachmentId,
      },
    };
  }

  static deleteFileFailed(attachmentId: string) {
    return {
      type: accountActionType.DELETE_FILE_FAILED,
      payload: {
        attachmentId,
      },
    };
  }

  static addFile(attachment: any) {
    return {
      type: accountActionType.ADD_FILE,
      payload: {
        attachment,
      },
    };
  }

  static addFileSuccess(response: any) {
    return {
      type: accountActionType.ADD_FILE_SUCCESS,
      payload: response,
    };
  }

  static addFileFailed(attachment: any) {
    return {
      type: accountActionType.ADD_FILE_FAILED,
      payload: {
        attachment,
      },
    };
  }

  static editFile(attachment: any) {
    return {
      type: accountActionType.EDIT_FILE,
      payload: {
        attachment,
      },
    };
  }

  static editFileSuccess(response: any) {
    return {
      type: accountActionType.EDIT_FILE_SUCCESS,
      payload: response,
    };
  }

  static editFileFailed(attachment: any) {
    return {
      type: accountActionType.EDIT_FILE_FAILED,
      payload: {
        attachment,
      },
    };
  }

  public static cancelAccount(data: CancelAccountRequest) {
    return {
      type: accountActionType.CANCEL_ACCOUNT,
      payload: {
        data,
      },
    };
  }

  static cancelAccountSuccess(response: any) {
    return {
      type: accountActionType.CANCEL_ACCOUNT_SUCCESS,
      payload: response,
    };
  }

  static cancelAccountFailed() {
    return {
      type: accountActionType.CANCEL_ACCOUNT_FAILED,
    };
  }

  static toggleSupportAccess() {
    return {
      type: accountActionType.TOGGLE_SUPPORT_ACCESS,
    };
  }

  static toggleSupportAccessSuccess(response: any) {
    return {
      type: accountActionType.TOGGLE_SUPPORT_ACCESS_SUCCESS,
      payload: response,
    };
  }

  static toggleSupportAccessFailed() {
    return {
      type: accountActionType.TOGGLE_SUPPORT_ACCESS_FAILED,
    };
  }

  static updateNotificationSettings() {
    return {
      type: accountActionType.UPDATE_NOTIFICATION_SETTINGS,
    };
  }

  static updateNotificationSettingsSuccess(response: any) {
    return {
      type: accountActionType.UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
      payload: response,
    };
  }

  static updateNotificationSettingsFailed() {
    return {
      type: accountActionType.UPDATE_NOTIFICATION_SETTINGS_FAILED,
    };
  }

  static fetchNotificationSettings() {
    return {
      type: accountActionType.FETCH_NOTIFICATION_SETTINGS,
    };
  }

  static fetchNotificationSettingsSuccess(response: any) {
    return {
      type: accountActionType.FETCH_NOTIFICATION_SETTINGS_SUCCESS,
      payload: response,
    };
  }

  static fetchNotificationSettingsFailed() {
    return {
      type: accountActionType.FETCH_NOTIFICATION_SETTINGS_FAILED,
    };
  }

  static setDefaultPermissionGroup(group: string) {
    return {
      type: accountActionType.SET_DEFAULT_PERMISSION_GROUP,
      payload: group,
    };
  }

  static updateAccountManager(userId: string) {
    return {
      type: accountActionType.UPDATE_ACCOUNT_MANAGER,
      payload: userId,
    };
  }
}
