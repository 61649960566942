import { Injectable } from '@angular/core';

function _window(): any {
  // return the native wind ow obj
  return window;
}

@Injectable()
export class WindowRef {
  HubSpotConversations: any;
  get nativeWindow(): any {
    return _window();
  }
}
