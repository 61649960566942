import { Injectable } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';

import { environment } from '../../../environments/environment';
import { ApiGateway } from '../../api/ApiGateway.service';
import { AuthService } from '../auth/auth.service';
import { UnsafeAction as Action } from '../interfaces';

@Injectable()
export class AttachmentApi {
  private endpoint = 'attachments/';

  public constructor(
    private gateway: ApiGateway,
    private authService: AuthService,
  ) {}

  private getConfig() {
    const authState = this.authService.getCurrentAuthState();
    return {
      autoUpload: true,
      method: 'POST',
      maxFileSize: 5242880,
      disableMultipart: true,
      isHTML5: true,
      headers: [
        {
          name: 'X-App-Type',
          value: 'Web App',
        },
        {
          name: 'X-App-Version',
          value: environment.version,
        },
        {
          name: 'X-App-Account',
          value: authState.account_id,
        },
        {
          name: 'X-App-User',
          value: authState.user_id,
        },
        {
          name: 'Accept',
          value: 'application/json',
        },
        {
          name: 'X-SHIFTBASE-CSRF-PROTECTION',
          value: '1',
        },
      ],
    };
  }

  public update(attachmentId, data, schema, dispatchStart?: Action) {
    // return this.gateway.put( this.endpoint + attachmentId, data, undefined, data )
    //   .map( res => reformatEntityResponse( 'Attachment', res ) )
    //   .map( data => normalize( data, schema, { assignEntity } ) );
  }

  public delete(attachmentId, dispatchStart?: Action) {
    return this.gateway.delete(this.endpoint + attachmentId + '/download', undefined, dispatchStart);
  }

  public download(attachmentId, fileName) {
    return this.gateway.download(this.endpoint + attachmentId + '/download', fileName);
  }

  public getUploader(uploadSuccessFn, failedFn) {
    const url = this.gateway.getFullUrl(this.endpoint + 'upload');

    const uploader = new FileUploader({
      ...this.getConfig(),
      queueLimit: 1,
      url,
    });

    // make sure we are allowed to upload
    uploader.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
    };

    uploader.onSuccessItem = uploadSuccessFn;
    uploader.onWhenAddingFileFailed = failedFn;
    uploader.onErrorItem = failedFn;

    return uploader;
  }

  public getUploaderMulti(uploadSuccessFn, failedFn, afterAddingFileFn) {
    const url = this.gateway.getFullUrl(this.endpoint + 'upload');

    const uploader = new FileUploader({
      ...this.getConfig(),
      url,
    });

    // make sure we are allowed to upload
    uploader.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
    };

    uploader.onAfterAddingFile = afterAddingFileFn;
    uploader.onSuccessItem = uploadSuccessFn;
    uploader.onWhenAddingFileFailed = failedFn;
    uploader.onErrorItem = failedFn;

    return uploader;
  }
}
