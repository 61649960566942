import { Injectable } from '@angular/core';
import { sortByDefault } from '@app/reducers/helpers/default-sorting.helper';
import { Dictionary } from '@ngrx/entity';
import { compose, Store } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import { createSelector } from 'reselect';
import { of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import u from 'updeep';

import { hexToRGB, isColorDark } from '../../../shared/contrast.helper';
import { AppState } from '../../index';
import { convertArrayToObject, filterDeleted, mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { TeamAction } from './team.action';
import { TeamApi } from './team.api';
import { TeamModel, TeamType } from './team.model';

const gray = '#98a0ab';

export const FallbackTeam: TeamModel = {
  id: '',
  account_id: '',
  department_id: '',
  name: '',
  type: TeamType.DEFAULT,
  color: gray,
  color_is_dark: true,
  color_rgb: hexToRGB(gray),
  order: '1000',
  hidden: false,
  deleted: false,
  deleted_date: null,
  created: null,
};

@Injectable()
export class TeamService {
  public constructor(
    private store: Store<AppState>,
    private api: TeamApi,
  ) {}

  public add(teamData) {
    return this.api.add(teamData, TeamAction.add(teamData)).pipe(
      map((response) => {
        this.store.dispatch(TeamAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((err) => {
        this.store.dispatch(TeamAction.addFailed(err));
        return observableThrowError(err);
      }),
    );
  }

  public update(teamId, teamData) {
    return this.api.update(teamId, teamData, TeamAction.update(teamData)).pipe(
      map((response) => {
        this.store.dispatch(TeamAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((err) => {
        this.store.dispatch(TeamAction.updateFailed(err));
        return observableThrowError(err);
      }),
    );
  }

  public batch(teams) {
    const data = { Team: teams };

    return this.api.batch(data).pipe(
      tap((response) => {
        this.store.dispatch(TeamAction.updateSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(TeamAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public validateRemove(teamId: string) {
    return this.api.validateRemove(teamId).pipe(catchError((err) => observableThrowError(err)));
  }

  public remove(teamId: string, departmentId: string, targetTeamId?: string) {
    return this.api.remove(teamId, TeamAction.remove(teamId), { targetTeamId: targetTeamId }).pipe(
      map((response) => {
        this.store.dispatch(TeamAction.removeSuccess(response, departmentId));
        return observableOf(response);
      }),
      catchError((err) => {
        this.store.dispatch(TeamAction.removeFailed(teamId, err));
        return observableThrowError(err);
      }),
    );
  }

  public save(teamData) {
    if (teamData.id) {
      return this.update(teamData.id, teamData);
    }

    teamData = u.omit('id', teamData);

    return this.add(teamData);
  }
}

export const getTeamState = (state: AppState) => state.orm.teams;

export const getTeamIds = compose((state) => state.items, getTeamState);

export const getTeamEntities = createSelector(getTeamState, (state) =>
  mapValues(state.itemsById, (team) => {
    const color_is_dark = isColorDark(team.color);
    const color_rgb = hexToRGB(team.color);

    return {
      ...team,
      color_is_dark,
      color_rgb,
    };
  }),
);

export const sortTeams = (teams: TeamModel[]) => sortByDefault<TeamModel>(teams);
export const mapAndSortTeams = mapAndSortEntities(sortTeams);

export const getTeams = createSelector(getTeamIds, getTeamEntities, mapAndSortTeams);

export const getTeam = (id: string) => createSelector(getTeamEntities, (entities) => mapEntity(id, entities));

export const getActiveTeams = createSelector(getTeams, (teams: TeamModel[]) => filterDeleted(teams));

export const getTeamsWithoutFlexpool = createSelector(getActiveTeams, (teams: TeamModel[]) =>
  convertArrayToObject(
    'id',
    teams.filter((team) => team.type !== TeamType.FLEXPOOL),
  ),
);

export const getActiveTeamsAmount = createSelector(getActiveTeams, (teams: TeamModel[]) => teams.length);

export const getVisibleTeams = (includeFlexpool: boolean = false) =>
  createSelector(getActiveTeams, (teams: TeamModel[]) =>
    teams.filter((team) => {
      if (includeFlexpool) {
        return team.type !== TeamType.HIDDEN;
      }

      return team.type === TeamType.DEFAULT;
    }),
  );

export const getVisibleTeamIds = createSelector(getVisibleTeams(), (teams: TeamModel[]) =>
  teams.map((team) => team.id),
);

export const groupTeamsByDepartment = <T>(teams): Dictionary<T[]> => groupBy(teams, 'department_id');
export const getActiveTeamsGroupedByDepartment = createSelector(getActiveTeams, (teams) => {
  const groupedTeams = groupTeamsByDepartment<TeamModel>(teams);
  return !!groupedTeams ? groupedTeams : {};
});

export const getVisibleTeamsGroupedByDepartment = (includeFlexpool: boolean = false) =>
  createSelector(getVisibleTeams(includeFlexpool), (teams) => {
    const groupedTeams = groupTeamsByDepartment(teams);
    return !!groupedTeams ? groupedTeams : [];
  });

export const getTeamsWithoutFlexpoolGroupedByDepartment = () =>
  createSelector(getTeamsWithoutFlexpool, (teams) => {
    const groupedTeams = groupTeamsByDepartment(teams);
    return !!groupedTeams ? groupedTeams : [];
  });

export const filterTeamOptionsPerDepartment =
  (departmentId, mustHaveId, includeFlexpool: boolean = false) =>
  (teams: TeamModel[]) =>
    teams.filter((team) => {
      if (team.department_id !== departmentId) {
        return false;
      }

      if (team.id === mustHaveId) {
        return true;
      }

      if (team.deleted) {
        return false;
      }

      if (includeFlexpool) {
        return team.type !== TeamType.HIDDEN;
      }

      return team.type === TeamType.DEFAULT;
    });
