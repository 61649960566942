/* eslint-disable max-lines */
import { NormalizeOutput } from 'normalizr';

import { UnsafeAction as Action } from '../../interfaces';
import { EmployeeTeamSavePayload } from './employee.model';

const ACTION_PREFIX = '[EmployeeModel]';

export const employeeActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  ACTIVATE: `${ACTION_PREFIX} Activate`,
  ACTIVATE_SUCCESS: `${ACTION_PREFIX} Activate Success`,
  ACTIVATE_FAILED: `${ACTION_PREFIX} Activate Failed`,

  MULTI_SAVE: `${ACTION_PREFIX} Multi Save`,
  MULTI_SAVE_SUCCESS: `${ACTION_PREFIX} Multi Save Success`,
  MULTI_SAVE_FAILED: `${ACTION_PREFIX} Multi Save Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,

  ANONYMIZE: `${ACTION_PREFIX} Anonymize`,
  ANONYMIZE_SUCCESS: `${ACTION_PREFIX} Anonymize Success`,
  ANONYMIZE_FAILED: `${ACTION_PREFIX} Anonymize Failed`,

  LOAD_FILES: `${ACTION_PREFIX} Load files`,
  LOAD_FILES_SUCCESS: `${ACTION_PREFIX} Load files Success`,
  LOAD_FILES_FAILED: `${ACTION_PREFIX} Load files Failed`,

  ADD_FILE: `${ACTION_PREFIX} Add file`,
  ADD_FILE_SUCCESS: `${ACTION_PREFIX} Add file Success`,
  ADD_FILE_FAILED: `${ACTION_PREFIX} Add file Failed`,

  EDIT_FILE: `${ACTION_PREFIX} Edit file`,
  EDIT_FILE_SUCCESS: `${ACTION_PREFIX} Edit file Success`,
  EDIT_FILE_FAILED: `${ACTION_PREFIX} Edit file Failed`,

  DELETE_FILE: `${ACTION_PREFIX} Delete file`,
  DELETE_FILE_SUCCESS: `${ACTION_PREFIX} Delete file Success`,
  DELETE_FILE_FAILED: `${ACTION_PREFIX} Delete file Failed`,

  LOAD_NOTES: `${ACTION_PREFIX} Load notes`,
  LOAD_NOTES_SUCCESS: `${ACTION_PREFIX} Load notes Success`,
  LOAD_NOTES_FAILED: `${ACTION_PREFIX} Load notes Failed`,

  ADD_NOTE: `${ACTION_PREFIX} Add note`,
  ADD_NOTE_SUCCESS: `${ACTION_PREFIX} Add note Success`,
  ADD_NOTE_FAILED: `${ACTION_PREFIX} Add note Failed`,

  EDIT_NOTE: `${ACTION_PREFIX} Edit note`,
  EDIT_NOTE_SUCCESS: `${ACTION_PREFIX} Edit note Success`,
  EDIT_NOTE_FAILED: `${ACTION_PREFIX} Edit note Failed`,

  DELETE_NOTE: `${ACTION_PREFIX} Delete note`,
  DELETE_NOTE_SUCCESS: `${ACTION_PREFIX} Delete note Success`,
  DELETE_NOTE_FAILED: `${ACTION_PREFIX} Delete note Failed`,

  UPDATE_PERMISSIONS: `${ACTION_PREFIX} Update permisions`,
  UPDATE_PERMISSIONS_SUCCESS: `${ACTION_PREFIX} Update permisions Success`,
  UPDATE_PERMISSIONS_FAILED: `${ACTION_PREFIX} Update permisions Failed`,

  UPDATE_TEAMS: `${ACTION_PREFIX} Update Teams`,
  UPDATE_TEAMS_SUCCESS: `${ACTION_PREFIX} Update Teams Success`,
  UPDATE_TEAMS_FAILED: `${ACTION_PREFIX} Update Teams Failed`,

  UPDATE_AVATAR: `${ACTION_PREFIX} Update avatar`,
  UPDATE_AVATAR_SUCCESS: `${ACTION_PREFIX} Update avatar Success`,
  UPDATE_AVATAR_FAILED: `${ACTION_PREFIX} Update avatar Failed`,

  BULK_ADD: `${ACTION_PREFIX} Bulk Add`,
  BULK_EMPTY: `${ACTION_PREFIX} Bulk Empty`,

  DELETE_AVATAR_SUCCESS: `${ACTION_PREFIX} Delete avatar Success`,
};

export class EmployeeAction {
  public static load(): Action {
    return {
      type: employeeActionType.LOAD,
    };
  }

  public static loadSuccess(employees): Action {
    return {
      type: employeeActionType.LOAD_SUCCESS,
      payload: employees,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: employeeActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(data): Action {
    return {
      type: employeeActionType.ADD,
      payload: data,
    };
  }

  public static addSuccess(Response): Action {
    return {
      type: employeeActionType.ADD_SUCCESS,
      payload: Response,
    };
  }

  public static addFailed(err): Action {
    return {
      type: employeeActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(data): Action {
    return {
      type: employeeActionType.UPDATE,
      payload: data,
    };
  }

  public static updateSuccess(Response): Action {
    return {
      type: employeeActionType.UPDATE_SUCCESS,
      payload: Response,
    };
  }

  public static updateFailed(err): Action {
    return {
      type: employeeActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  public static activate(data): Action {
    return {
      type: employeeActionType.ACTIVATE,
      payload: data,
    };
  }

  public static activateSuccess(Response): Action {
    return {
      type: employeeActionType.ACTIVATE_SUCCESS,
      payload: Response,
    };
  }

  public static activateFailed(err): Action {
    return {
      type: employeeActionType.ACTIVATE_FAILED,
      payload: err,
    };
  }

  public static saveMany(data): Action {
    return {
      type: employeeActionType.MULTI_SAVE,
      payload: data,
    };
  }

  public static saveManySuccess(Response): Action {
    return {
      type: employeeActionType.MULTI_SAVE_SUCCESS,
      payload: Response,
    };
  }

  public static saveManyFailed(err): Action {
    return {
      type: employeeActionType.MULTI_SAVE_FAILED,
      payload: err,
    };
  }

  public static fetch(id: any) {
    return {
      type: employeeActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: employeeActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: employeeActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static remove(id: string) {
    return {
      type: employeeActionType.REMOVE,
      payload: id,
    };
  }

  public static removeSuccess(id: string) {
    return {
      type: employeeActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }

  public static removeFailed(id: string, err) {
    return {
      type: employeeActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static anonymize(id: string) {
    return {
      type: employeeActionType.ANONYMIZE,
      payload: id,
    };
  }

  public static anonymizeSuccess(data) {
    return {
      type: employeeActionType.ANONYMIZE_SUCCESS,
      payload: data,
    };
  }

  public static anonymizeFailed(id: string, err) {
    return {
      type: employeeActionType.ANONYMIZE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  /*****************
   * USER FILES
   *****************/

  public static loadFiles(employeeId: any) {
    return {
      type: employeeActionType.LOAD_FILES,
      payload: {
        employeeId,
      },
    };
  }

  public static loadFilesSuccess(employeeId, response) {
    return {
      type: employeeActionType.LOAD_FILES_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static loadFilesFailed(employeeId: string, err) {
    return {
      type: employeeActionType.LOAD_FILES_FAILED,
      payload: {
        employeeId,
        err,
      },
    };
  }

  public static deleteFile(employeeId: any, attachmentId: string) {
    return {
      type: employeeActionType.DELETE_FILE,
      payload: {
        employeeId,
        attachmentId,
      },
    };
  }

  public static deleteFileSuccess(employeeId: any, attachmentId: string) {
    return {
      type: employeeActionType.DELETE_FILE_SUCCESS,
      payload: {
        employeeId,
        attachmentId,
      },
    };
  }

  public static deleteFileFailed(employeeId: any, attachmentId: string) {
    return {
      type: employeeActionType.DELETE_FILE_FAILED,
      payload: {
        employeeId,
        attachmentId,
      },
    };
  }

  public static addFile(employeeId: any, attachment: any) {
    return {
      type: employeeActionType.ADD_FILE,
      payload: {
        employeeId,
        attachment,
      },
    };
  }

  public static addFileSuccess(employeeId: any, response: any) {
    return {
      type: employeeActionType.ADD_FILE_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static addFileFailed(employeeId: any, attachment: any) {
    return {
      type: employeeActionType.ADD_FILE_FAILED,
      payload: {
        employeeId,
        attachment,
      },
    };
  }

  public static editFile(employeeId: any, attachment: any) {
    return {
      type: employeeActionType.EDIT_FILE,
      payload: {
        employeeId,
        attachment,
      },
    };
  }

  public static editFileSuccess(employeeId: any, response: any) {
    return {
      type: employeeActionType.EDIT_FILE_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static editFileFailed(employeeId: any, attachment: any) {
    return {
      type: employeeActionType.EDIT_FILE_FAILED,
      payload: {
        employeeId,
        attachment,
      },
    };
  }

  /*****************
   * USER NOTES
   *****************/

  public static loadNotes(employeeId: any) {
    return {
      type: employeeActionType.LOAD_NOTES,
      payload: {
        employeeId,
      },
    };
  }

  public static loadNotesSuccess(employeeId, response) {
    return {
      type: employeeActionType.LOAD_NOTES_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static loadNotesFailed(employeeId: string, err) {
    return {
      type: employeeActionType.LOAD_NOTES_FAILED,
      payload: {
        employeeId,
        err,
      },
    };
  }

  public static deleteNote(employeeId: any, userNoteId: string) {
    return {
      type: employeeActionType.DELETE_NOTE,
      payload: {
        employeeId,
        userNoteId,
      },
    };
  }

  public static deleteNoteSuccess(employeeId: any, userNoteId: string) {
    return {
      type: employeeActionType.DELETE_NOTE_SUCCESS,
      payload: {
        employeeId,
        userNoteId,
      },
    };
  }

  public static deleteNoteFailed(employeeId: any, userNoteId: string) {
    return {
      type: employeeActionType.DELETE_NOTE_FAILED,
      payload: {
        employeeId,
        userNoteId,
      },
    };
  }

  public static addNote(employeeId: any, userNote: any) {
    return {
      type: employeeActionType.ADD_NOTE,
      payload: {
        employeeId,
        userNote,
      },
    };
  }

  public static addNoteSuccess(employeeId: any, response: any) {
    return {
      type: employeeActionType.ADD_NOTE_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static addNoteFailed(employeeId: any, userNote: any) {
    return {
      type: employeeActionType.ADD_NOTE_FAILED,
      payload: {
        employeeId,
        userNote,
      },
    };
  }

  public static editNote(employeeId: any, userNote: any) {
    return {
      type: employeeActionType.EDIT_NOTE,
      payload: {
        employeeId,
        userNote,
      },
    };
  }

  public static editNoteSuccess(employeeId: any, response: any) {
    return {
      type: employeeActionType.EDIT_NOTE_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static editNoteFailed(employeeId: any, userNote: any) {
    return {
      type: employeeActionType.EDIT_NOTE_FAILED,
      payload: {
        employeeId,
        userNote,
      },
    };
  }

  public static updatePermissions(employeeId, data): Action {
    return {
      type: employeeActionType.UPDATE_PERMISSIONS,
      payload: {
        employeeId,
        data,
      },
    };
  }

  public static updatePermissionsSuccess(employeeId, response): Action {
    return {
      type: employeeActionType.UPDATE_PERMISSIONS_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static updatePermissionsFailed(employeeId, err): Action {
    return {
      type: employeeActionType.UPDATE_PERMISSIONS_FAILED,
      payload: {
        employeeId,
        err,
      },
    };
  }

  public static updateTeams(employeeId: string, data: EmployeeTeamSavePayload): Action {
    return {
      type: employeeActionType.UPDATE_TEAMS,
      payload: {
        employeeId,
        data,
      },
    };
  }

  public static updateTeamsSuccess(employeeId: string, response: NormalizeOutput): Action {
    return {
      type: employeeActionType.UPDATE_TEAMS_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static updateTeamsFailed(employeeId: string, err: any): Action {
    return {
      type: employeeActionType.UPDATE_TEAMS_FAILED,
      payload: {
        employeeId,
        err,
      },
    };
  }

  public static updateAvatar(employeeId, data): Action {
    return {
      type: employeeActionType.UPDATE_AVATAR,
      payload: {
        employeeId,
        data,
      },
    };
  }

  public static updateAvatarSuccess(employeeId, response): Action {
    return {
      type: employeeActionType.UPDATE_AVATAR_SUCCESS,
      payload: {
        employeeId,
        response,
      },
    };
  }

  public static updateAvatarFailed(employeeId, err): Action {
    return {
      type: employeeActionType.UPDATE_AVATAR_FAILED,
      payload: {
        employeeId,
        err,
      },
    };
  }

  public static bulkAdd(selectedEmployeeIds) {
    return {
      type: employeeActionType.BULK_ADD,
      payload: selectedEmployeeIds,
    };
  }

  public static bulkEmpty() {
    return {
      type: employeeActionType.BULK_EMPTY,
    };
  }

  public static deleteAvatarSuccess(employeeId: string) {
    return {
      type: employeeActionType.DELETE_AVATAR_SUCCESS,
      payload: {
        employeeId,
      },
    };
  }
}
