const ACTION_PREFIX = '[Selected departments]';
export const selectedDepartmentsActionType = {
  TOGGLE_DEPARTMENT: `${ACTION_PREFIX} toggle department`,
  TOGGLE_DEPARTMENTS: `${ACTION_PREFIX} toggle departments`,
  LOAD_DEPARTMENTS: `${ACTION_PREFIX} load departments`,
  SELECT_DEPARTMENT: `${ACTION_PREFIX} select department`,
  SELECT_DEPARTMENTS: `${ACTION_PREFIX} select departments`,
};

export class SelectedDepartmentsAction {
  static toggleDepartment(departmentId) {
    return {
      type: selectedDepartmentsActionType.TOGGLE_DEPARTMENT,
      payload: departmentId,
    };
  }

  static toggleDepartments(departmentIds) {
    return {
      type: selectedDepartmentsActionType.TOGGLE_DEPARTMENTS,
      payload: departmentIds,
    };
  }

  static selectDepartment(departmentId) {
    return {
      type: selectedDepartmentsActionType.SELECT_DEPARTMENT,
      payload: departmentId,
    };
  }

  static loadDepartments(departmentIds) {
    return {
      type: selectedDepartmentsActionType.LOAD_DEPARTMENTS,
      payload: departmentIds,
    };
  }

  static selectDepartments(departmentIds) {
    return {
      type: selectedDepartmentsActionType.SELECT_DEPARTMENTS,
      payload: departmentIds,
    };
  }
}
