import { UnsafeAction as Action } from '../interfaces';
import { AuthRequest, AuthResponse } from './auth.model';

const ACTION_PREFIX = '[Auth]';
export const authActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  LOGIN: `${ACTION_PREFIX} Login`,
  LOGIN_SUCCESS: `${ACTION_PREFIX} Login Success`,
  LOGIN_FAILED: `${ACTION_PREFIX} Login Failed`,

  LOGOUT: `${ACTION_PREFIX} Logout`,
  LOGOUT_SUCCESS: `${ACTION_PREFIX} Logout Success`,
  LOGOUT_FAILED: `${ACTION_PREFIX} Logout Failed`,

  SIGN_UP: `${ACTION_PREFIX} Sign Up`,
  SIGN_UP_SUCCESS: `${ACTION_PREFIX} Sign Up Success`,
  SIGN_UP_FAILED: `${ACTION_PREFIX} Sign Up Failed`,

  SIGN_UP_NO_VERIFY: `${ACTION_PREFIX} Sign Up No Verify`,
  SIGN_UP_NO_VERIFY_SUCCESS: `${ACTION_PREFIX} Sign Up No Verify Success`,
  SIGN_UP_NO_VERIFY_FAILED: `${ACTION_PREFIX} Sign Up No Verify Failed`,

  VERIFY_SIGN_UP: `${ACTION_PREFIX} Verify Sign Up`,
  VERIFY_SIGN_UP_SUCCESS: `${ACTION_PREFIX} Verify Sign Up Success`,
  VERIFY_SIGN_UP_FAILED: `${ACTION_PREFIX} Verify Sign Up Failed`,

  FORGOT_PASSWORD: `${ACTION_PREFIX} Forgot Password`,
  FORGOT_PASSWORD_SUCCESS: `${ACTION_PREFIX} Forgot Password Success`,
  FORGOT_PASSWORD_FAILED: `${ACTION_PREFIX} Forgot Password Failed`,

  CHANGE_PASSWORD: `${ACTION_PREFIX} Change Password`,
  CHANGE_PASSWORD_SUCCESS: `${ACTION_PREFIX} Change Password Success`,
  CHANGE_PASSWORD_FAILED: `${ACTION_PREFIX} Change Password Failed`,

  RESET_PASSWORD: `${ACTION_PREFIX} Reset Password`,
  RESET_PASSWORD_SUCCESS: `${ACTION_PREFIX} Reset Password Success`,
  RESET_PASSWORD_FAILED: `${ACTION_PREFIX} Reset Password Failed`,

  CHANGE_EMAIL: `${ACTION_PREFIX} Change Email`,
  CHANGE_EMAIL_SUCCESS: `${ACTION_PREFIX} Change Email Success`,
  CHANGE_EMAIL_FAILED: `${ACTION_PREFIX} Change Email Failed`,

  REFRESH: `${ACTION_PREFIX} Refresh`,
  REFRESH_SUCCESS: `${ACTION_PREFIX} Refresh Success`,
  REFRESH_FAILED: `${ACTION_PREFIX} Refresh Failed`,

  LOGIN_CHOOSE_ACCOUNT: `${ACTION_PREFIX} Choose Account`,
  CLEAR_SESSION: `${ACTION_PREFIX} clear session`,
};

export class AuthAction {
  static login(auth: AuthRequest): Action {
    return {
      type: authActionType.LOGIN,
      payload: auth,
    };
  }

  static loginSuccess(response: AuthResponse): Action {
    return {
      type: authActionType.LOGIN_SUCCESS,
      payload: response,
    };
  }

  static loginFailed(): Action {
    return {
      type: authActionType.LOGIN_FAILED,
    };
  }

  static loginChooseAccount(accounts): Action {
    return {
      type: authActionType.LOGIN_CHOOSE_ACCOUNT,
      payload: accounts,
    };
  }

  static load(): Action {
    return {
      type: authActionType.LOAD,
    };
  }

  static loadSuccess(response: AuthResponse): Action {
    return {
      type: authActionType.LOAD_SUCCESS,
      payload: response,
    };
  }

  static loadFailed(): Action {
    return {
      type: authActionType.LOAD_FAILED,
    };
  }

  static refresh(): Action {
    return {
      type: authActionType.REFRESH,
    };
  }

  static refreshSuccess(response: AuthResponse): Action {
    return {
      type: authActionType.REFRESH_SUCCESS,
      payload: response,
    };
  }

  static refreshFailed(): Action {
    return {
      type: authActionType.REFRESH_FAILED,
    };
  }

  static logout() {
    return {
      type: authActionType.LOGOUT,
    };
  }

  static logoutSuccess(): Action {
    return {
      type: authActionType.LOGOUT_SUCCESS,
    };
  }

  static logoutFailed(): Action {
    return {
      type: authActionType.LOGOUT_FAILED,
    };
  }

  static signUp() {
    return {
      type: authActionType.SIGN_UP,
    };
  }

  static signUpSuccess(): Action {
    return {
      type: authActionType.SIGN_UP_SUCCESS,
    };
  }

  static signUpFailed(): Action {
    return {
      type: authActionType.SIGN_UP_FAILED,
    };
  }

  static signUpNoVerify() {
    return {
      type: authActionType.SIGN_UP_NO_VERIFY,
    };
  }

  static signUpNoVerifySuccess(response): Action {
    return {
      payload: response,
      type: authActionType.SIGN_UP_NO_VERIFY_SUCCESS,
    };
  }

  static signUpNoVerifyFailed(): Action {
    return {
      type: authActionType.SIGN_UP_NO_VERIFY_FAILED,
    };
  }

  static verifySignUp() {
    return {
      type: authActionType.VERIFY_SIGN_UP,
    };
  }

  static verifySignUpSuccess(response): Action {
    return {
      payload: response,
      type: authActionType.VERIFY_SIGN_UP_SUCCESS,
    };
  }

  static verifySignUpFailed(): Action {
    return {
      type: authActionType.VERIFY_SIGN_UP_FAILED,
    };
  }

  static clearSession() {
    return {
      type: authActionType.CLEAR_SESSION,
    };
  }

  static forgotPassword() {
    return {
      type: authActionType.FORGOT_PASSWORD,
    };
  }

  static forgotPasswordSuccess(): Action {
    return {
      type: authActionType.FORGOT_PASSWORD_SUCCESS,
    };
  }

  static forgotPasswordFailed(): Action {
    return {
      type: authActionType.FORGOT_PASSWORD_FAILED,
    };
  }

  static changePassword() {
    return {
      type: authActionType.CHANGE_PASSWORD,
    };
  }

  static changePasswordSuccess(): Action {
    return {
      type: authActionType.CHANGE_PASSWORD_SUCCESS,
    };
  }

  static changePasswordFailed(): Action {
    return {
      type: authActionType.CHANGE_PASSWORD_FAILED,
    };
  }

  static resetPassword() {
    return {
      type: authActionType.RESET_PASSWORD,
    };
  }

  static resetPasswordSuccess(response: AuthResponse): Action {
    return {
      type: authActionType.RESET_PASSWORD_SUCCESS,
      payload: response,
    };
  }

  static resetPasswordFailed(): Action {
    return {
      type: authActionType.RESET_PASSWORD_FAILED,
    };
  }

  static changeEmail() {
    return {
      type: authActionType.CHANGE_EMAIL,
    };
  }

  static changeEmailSuccess(response: AuthResponse): Action {
    return {
      type: authActionType.CHANGE_EMAIL_SUCCESS,
      payload: response,
    };
  }

  static changeEmailFailed(): Action {
    return {
      type: authActionType.CHANGE_EMAIL_FAILED,
    };
  }
}
