import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Team]';

export const teamActionType = {
  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,
};

export class TeamAction {
  static add(teamData): Action {
    return {
      type: teamActionType.ADD,
      payload: teamData,
    };
  }

  static addSuccess(teamResponse): Action {
    return {
      type: teamActionType.ADD_SUCCESS,
      payload: teamResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: teamActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(teamData): Action {
    return {
      type: teamActionType.UPDATE,
      payload: teamData,
    };
  }

  static updateSuccess(teamResponse): Action {
    return {
      type: teamActionType.UPDATE_SUCCESS,
      payload: teamResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: teamActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static remove(id): Action {
    return {
      type: teamActionType.REMOVE,
      payload: id,
    };
  }

  static removeSuccess(id, departmentId: string): Action {
    return {
      type: teamActionType.REMOVE_SUCCESS,
      payload: id,
      departmentId,
    };
  }

  static removeFailed(id, err): Action {
    return {
      type: teamActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
