import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { TeamSchema } from '../../shared/schemas';

@Injectable()
export class TeamApi {
  private endpoint = 'teams/';

  public constructor(private gateway: ApiGateway) {}

  public add(teamData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, teamData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Team', res)),
      map((data) => normalize(data, TeamSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(teamId, teamData, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + teamId, teamData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Team', res)),
      map((data) => normalize(data, TeamSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public batch(teams): Observable<any> {
    return this.gateway.post(this.endpoint + 'batch', teams, undefined).pipe(
      map((res) => reformatListResponse('Team', res)),
      map((res) => normalize(res, arrayOf(TeamSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(teamId, dispatchStart?: Action, data?: { targetTeamId: string }): Observable<any> {
    return this.gateway.delete(this.endpoint + teamId, undefined, dispatchStart, data);
  }

  public validateRemove(teamId: string): Observable<any> {
    return this.gateway.delete(this.endpoint + teamId + '/validate', { headers: { ignoreMetaMessage: true } });
  }
}
